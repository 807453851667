import OnboardingMachineProvider from './components/machine-provider';
import Router from './pages/router';
import type { OnboardingProps } from './types';

const Onboarding = ({ onDone, ...args }: OnboardingProps) => {
  return (
    <OnboardingMachineProvider args={args}>
      <Router onDone={onDone} />
    </OnboardingMachineProvider>
  );
};

export default Onboarding;
