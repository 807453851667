import { patchHostedIdentifySessionVault } from '@onefootprint/axios';
import { useRequestError } from '@onefootprint/request';
import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import LegalFooter from '../../legal-footer';
import type { Context } from '../identify.types';
import getHeader from '../utils/get-header';
import EmailPageStructure from './email-page-structure';

type CollectEmailProps = {
  context: Context;
  onPrev?: () => void;
  onDone: (email: string) => Promise<void>;
};

const CollectEmail = ({ context, onPrev, onDone }: CollectEmailProps) => {
  const {
    state: { identifyToken, email, isLoggedIn },
    onError,
    initArgs: { logoConfig },
  } = context;
  const { t } = useTranslation('identify');
  const { getErrorContext, getErrorCode } = useRequestError();
  const vaultMutation = useMutation({
    mutationFn: async (emailToVault: string | null) => {
      const { data } = await patchHostedIdentifySessionVault({
        headers: { 'X-Fp-Authorization': identifyToken },
        // @ts-expect-error: Autogenerated types don't support null values
        body: { 'id.email': emailToVault },
        throwOnError: true,
      });
      if (emailToVault) {
        await onDone(emailToVault);
      }
      return data;
    },
    onError: error => {
      const isVaultDataValidationError = getErrorCode(error) === 'T120';
      const context = isVaultDataValidationError ? getErrorContext(error) : {};
      const errorMessage = context['id.email'] ?? error;
      onError('collect-email', errorMessage);
    },
  });

  const handleSubmit = (emailFromForm: string) => {
    vaultMutation.mutate(emailFromForm);
  };

  // Clear out the email if the user goes back
  const handleGoBack = onPrev
    ? () => {
        vaultMutation.mutate(null, { onSuccess: onPrev });
      }
    : undefined;

  return (
    <EmailPageStructure
      defaultEmail={email?.value}
      Header={getHeader(logoConfig, handleGoBack)}
      Footer={!context.state.isLoggedIn ? <LegalFooter /> : undefined}
      isLoading={vaultMutation.isPending}
      onSubmit={vaultMutation.isPending ? noop : handleSubmit}
      texts={{
        header: {
          title: t('email-step.title'),
          subtitle: isLoggedIn ? t('email-step.subtitle-logged-in') : t('email-step.subtitle'),
        },
        email: {
          invalid: t('email.errors.invalid'),
          label: t('email.label'),
          placeholder: t('email.placeholder'),
          required: t('email.errors.required'),
        },
        cta: t('continue'),
      }}
    />
  );
};

export default CollectEmail;
