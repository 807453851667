import Markdown from 'react-markdown';

type MarkdownProps = React.ComponentProps<typeof Markdown>;

const MarkdownText = (props: MarkdownProps) => {
  return (
    <Markdown
      {...props}
      components={{
        h1: ({ children }) => <h1 className="text-heading-1">{children}</h1>,
        h2: ({ children }) => <h2 className="text-heading-2">{children}</h2>,
        h3: ({ children }) => <h3 className="text-heading-3">{children}</h3>,
        h4: ({ children }) => <h4 className="text-heading-4">{children}</h4>,
        h5: ({ children }) => <h5 className="text-heading-5">{children}</h5>,
        h6: ({ children }) => <h6 className="text-heading-6">{children}</h6>,
        blockquote: ({ children }) => (
          <blockquote className="text-gray-500 m-0 pl-12 border-l-8 border-gray-200">{children}</blockquote>
        ),
        a: ({ children, href }) => (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-[#2D2D2D] hover:text-[#1A1A1A] focus:outline-none focus:ring-2 focus:ring-[#2D2D2D]"
          >
            {children}
          </a>
        ),
        li: ({ children }) => <li className="mb-2">{children}</li>,
        ol: ({ children }) => <ol className="list-decimal list-outside space-y-2 pl-5">{children}</ol>,
        ul: ({ children }) => <ul className="list-disc list-inside space-y-2 pl-5">{children}</ul>,
      }}
    />
  );
};

export default MarkdownText;
