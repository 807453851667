import MarkdownText from '@/idv/components/markdown-text';
import { getLogger } from '@/idv/utils';
import type { CustomRequirementSection } from '@onefootprint/request-types';
import { Button } from '@onefootprint/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CustomDisType, CustomDisValueType, FormData } from '../../../../types';
import FormField from './components/form-field';

type FormProps = {
  sections: CustomRequirementSection[];
  onSubmit: (data: FormData) => void;
  submissionPending?: boolean;
};

const flatten = (data: object, prefix: string = '') => {
  const result: Record<string, CustomDisValueType> = {};

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      // Check for consecutive numeric keys starting from 0
      const numKeys = Object.keys(value)
        .filter(k => !Number.isNaN(Number(k)))
        .map(Number)
        .sort((a, b) => a - b);
      const isArrayLike = numKeys.length > 0 && numKeys[0] === 0 && numKeys.every((n, i) => n === i);

      if (isArrayLike) {
        // Recursively flatten nested objects within array
        result[prefix + key] = numKeys.map(k => (typeof value[k] === 'object' ? flatten(value[k]) : value[k]));
      } else {
        // Recursively flatten non-array objects
        Object.assign(result, flatten(value, `${prefix}${key}.`));
      }
    } else {
      result[`${prefix}${key}`] = value;
    }
  });

  return result;
};

const isCustomDisValue = (value: unknown): value is CustomDisValueType => {
  return typeof value === 'string' || typeof value === 'boolean' || Array.isArray(value) || value === undefined;
};

const Form = ({ sections, onSubmit, submissionPending }: FormProps) => {
  const { t } = useTranslation('idv', { keyPrefix: 'collect-custom-data.pages.custom-data-form' });
  const { handleSubmit } = useFormContext<FormData>();
  const { logError } = getLogger({ location: 'collect-custom-data:form' });

  const formatBeforeSubmit = (data: FormData): FormData => {
    const formattedData: FormData = {};
    const flattenedData = flatten(data);

    Object.entries(flattenedData).forEach(([key, value]) => {
      if ((typeof value === 'boolean' || (!!value && value !== '')) && isCustomDisValue(value)) {
        if (key.startsWith('custom.')) {
          formattedData[key as CustomDisType] = value;
        } else {
          logError(`Invalid key ${key} found in custom-collect-data form data while formatting for submission`);
        }
      }
    });

    return formattedData;
  };

  const handleBeforeSubmit = (data: FormData) => {
    const formattedData = formatBeforeSubmit(data);
    onSubmit(formattedData);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(handleBeforeSubmit)}>
      {sections.map(section => {
        if (section.kind === 'prompt') {
          return <FormField key={section.di} prompt={section} />;
        }
        if (section.kind === 'text_block') {
          return <MarkdownText className="text-body-3">{section.content}</MarkdownText>;
        }
        return null;
      })}
      <Button
        type="submit"
        data-dd-action-name="custom-data-collection:save"
        loading={!!submissionPending}
        size="large"
      >
        {t('cta')}
      </Button>
    </form>
  );
};

export default Form;
