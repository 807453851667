import { DocumentRequestKind, type PublicOnboardingConfig } from '@onefootprint/types';
import { useTranslation } from 'react-i18next';

const useGuidelines = ({ docKind, config }: { docKind: DocumentRequestKind; config: PublicOnboardingConfig }) => {
  const { t } = useTranslation('idv', {
    keyPrefix: 'document-flow.non-id-doc.pages.document-prompt.guidelines',
  });
  const shouldOmitBankCreditCardStatement = config.flags.noBankCreditCardStatementForPoA;
  const poaGuidelines = [
    t('gas-water-electricity-bill'),
    t('vehicle-voter-registration'),
    t('rental-agreement'),
    t('phone-internet-bill'),
  ];
  if (!shouldOmitBankCreditCardStatement) {
    poaGuidelines.push(t('bank-credit-card-statement'));
  }
  const dockindToGuidelines: Record<DocumentRequestKind, string[]> = {
    [DocumentRequestKind.ProofOfAddress]: poaGuidelines,
    [DocumentRequestKind.ProofOfSsn]: [],
    [DocumentRequestKind.Identity]: [],
    [DocumentRequestKind.Custom]: [],
  };

  return dockindToGuidelines[docKind];
};

export default useGuidelines;
