import type { OnboardingRequirement, OnboardingStatusResponse } from '@onefootprint/types';
import { OnboardingRequirementKind } from '@onefootprint/types';
import { requirementKey } from '../../../../utils/is-repeat-requirement';

type MachineContext = {
  isComponentsSdk: boolean;
  isRequirementRouterVisited: boolean;
  isTransfer: boolean;
  handledRequirements: string[];
};

const swapOrder = (requirements: OnboardingRequirement[], from: number, to: number) => {
  const newArray = [...requirements];

  const temp = newArray[to];
  newArray[to] = newArray[from];
  newArray[from] = temp;

  return newArray;
};

// If we have a custom document with upload settings set as "prefer_upload",
// we should first request the user to upload the document before transferring
const swapPreferUploadWithLiveness = (requirements: OnboardingRequirement[]): OnboardingRequirement[] => {
  const preferUploadIndex = requirements.findIndex(
    requirement =>
      requirement.kind === OnboardingRequirementKind.document && requirement.uploadSettings === 'prefer_upload',
  );
  if (preferUploadIndex === -1) return requirements;
  const livenessIndex = requirements.findIndex(r => r.kind === OnboardingRequirementKind.registerPasskey);
  if (livenessIndex === -1) return requirements;
  return swapOrder(requirements, livenessIndex, preferUploadIndex);
};

/**
 * Given the list of requirements from the backend and some information about which requirements
 * we've already displayed, computes the frontend
 *
 * @param {MachineContext} ctx
 * @param {OnboardingStatusResponse} res
 * @returns {OnboardingRequirement[]}
 */
export const filterRequirementsToShow = (
  ctx: MachineContext,
  res: OnboardingStatusResponse,
): OnboardingRequirement[] => {
  const { allRequirements } = res;
  const unmetRequirements: OnboardingRequirement[] = allRequirements
    .filter(r => r.kind !== OnboardingRequirementKind.registerAuthMethod)
    .filter(r => !r.isMet);

  if (!ctx.isRequirementRouterVisited && !unmetRequirements.length) {
    // If we haven't started data collection (== this is the first time we've checked requirements),
    // and if there are no unmet requirements, short circuit through all requirements.
    // This handles the case where someone tries to onboard onto the exact same onboarding config.
    return [];
  }

  // We want to show all unmet requirements, plus a few met requirements only once.
  // Note that we must PRESERVE THE ORDER of the requirements as given to us by the backend
  const requirements: OnboardingRequirement[] = allRequirements.filter((r: OnboardingRequirement) => {
    /** Take all unmet requirements */
    if (!r.isMet) return true;

    const isCollectKyc = r.kind === OnboardingRequirementKind.collectKycData;
    const isCollectKyb = r.kind === OnboardingRequirementKind.collectKybData;
    const isInvProfile = r.kind === OnboardingRequirementKind.investorProfile;
    const isCustomData = r.kind === OnboardingRequirementKind.collectCustomData;
    if (isCollectKyc || isCollectKyb || isInvProfile || isCustomData) {
      // Don't show these requirements if we've already handled them, we're in the components SDK, or we're
      // in the mobile handoff app
      const alreadyHandled = ctx.handledRequirements.includes(requirementKey(r));
      return !alreadyHandled && !ctx.isComponentsSdk && !ctx.isTransfer;
    }

    return false;
  });

  return swapPreferUploadWithLiveness(requirements);
};

export default filterRequirementsToShow;
