import type { HostedBusinessOwner } from '@onefootprint/request-types';
import type { ManageBosFormData } from '../../manage-bos.types';

const overlayUpdates = (existingOwners: HostedBusinessOwner[], { bos, bosToDelete }: ManageBosFormData) => {
  const existingOwnersAfterDelete = existingOwners.filter(bo => !bosToDelete.includes(bo.uuid));
  const allOwnersByUuid = {
    ...Object.fromEntries(existingOwnersAfterDelete.map(owner => [owner.uuid, owner])),
    ...Object.fromEntries(bos.map(owner => [owner.uuid, owner])),
  };
  return allOwnersByUuid;
};

export const sumTotalOwnershipStake = (
  existingOwners: HostedBusinessOwner[],
  { bos, bosToDelete }: ManageBosFormData,
): number => {
  const allOwnersByUuid = overlayUpdates(existingOwners, { bos, bosToDelete });
  const totalStake = Object.values(allOwnersByUuid)
    .map(bo => bo.ownershipStake ?? 0)
    .reduce((sum, stake) => sum + stake, 0);
  return totalStake;
};

export const hasDuplicatedEmail = (
  existingOwners: HostedBusinessOwner[],
  { bos, bosToDelete }: ManageBosFormData,
): boolean => {
  const existingOwnersAfterDelete = existingOwners.filter(bo => !bosToDelete.includes(bo.uuid));
  const allEmailsByUuid = {
    ...Object.fromEntries(existingOwnersAfterDelete.map(owner => [owner.uuid, owner.decryptedData['id.email']])),
    ...Object.fromEntries(bos.map(owner => [owner.uuid, owner.email])),
  };
  const allEmails = Object.values(allEmailsByUuid).filter(Boolean);
  return allEmails.length > 0 && new Set(allEmails).size !== allEmails.length;
};

export const hasDuplicatedPhoneNumber = (
  existingOwners: HostedBusinessOwner[],
  { bos, bosToDelete }: ManageBosFormData,
): boolean => {
  const existingOwnersAfterDelete = existingOwners.filter(bo => !bosToDelete.includes(bo.uuid));
  const allPhoneNumbersByUuid = {
    ...Object.fromEntries(existingOwnersAfterDelete.map(owner => [owner.uuid, owner.decryptedData['id.phone_number']])),
    ...Object.fromEntries(bos.map(owner => [owner.uuid, owner.phoneNumber])),
  };
  const allPhoneNumbers = Object.values(allPhoneNumbersByUuid).filter(Boolean).map(normalizePhoneNumber);
  if (allPhoneNumbers.length === 0) {
    return false;
  }
  const uniquePhoneNumbers = new Set(allPhoneNumbers);
  return uniquePhoneNumbers.size !== allPhoneNumbers.length;
};

const normalizePhoneNumber = (phone?: string) => {
  if (!phone) return '';
  const digitsOnly = phone.replace(/\D/g, '');
  const withoutLeadingZeros = digitsOnly.replace(/^0+/, '');
  return withoutLeadingZeros.replace(/^\+/, '');
};
