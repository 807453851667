import { useDecryptUser } from '@/idv/queries';
import { getLogger } from '@/idv/utils';
import type { CommonIdvContext } from '@/idv/utils/state-machine';
import { patchHostedUserVault, postHostedUserVaultDecryptMutation } from '@onefootprint/axios';
import { useRequestErrorToast } from '@onefootprint/hooks';
import type { OnboardingRequirementCollectCustomData, PatchHostedUserVaultData } from '@onefootprint/request-types';
import { LoadingSpinner } from '@onefootprint/ui';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import CustomDataForm from './pages/custom-data-form';
import type { CustomDisType, FormData } from './types';

type CollectCustomDataProps = {
  collectCustomDataRequirement: OnboardingRequirementCollectCustomData;
  idvContext: CommonIdvContext;
  onDone: () => void;
};

const CollectCustomData = ({ collectCustomDataRequirement, idvContext, onDone }: CollectCustomDataProps) => {
  const mutDecryptUser = useDecryptUser();
  const { logError } = getLogger({ location: 'collect-custom-data:index' });
  const { authToken } = idvContext;
  const errorToast = useRequestErrorToast();
  const customDataDis = collectCustomDataRequirement.page.sections
    .filter(section => section.kind === 'prompt')
    .map(prompt => {
      return prompt.di;
    })
    .filter(di => di.startsWith('custom.')) as CustomDisType[];
  const decryptUserMutation = useMutation(
    postHostedUserVaultDecryptMutation({
      body: {
        fields: customDataDis,
      },
      headers: {
        'X-Fp-Authorization': authToken,
      },
    }),
  );
  const decryptedCustomData = (decryptUserMutation.data ?? {}) as FormData; // can cast to FormData because we only decrypt custom data

  const vaultMutation = useMutation({
    mutationFn: async (request: PatchHostedUserVaultData) => {
      const { data } = await patchHostedUserVault({
        ...request,
        throwOnError: true,
      });
      return data;
    },
  });

  const handleSubmitData = (data: FormData) => {
    vaultMutation.mutate(
      { body: data, headers: { 'X-Fp-Authorization': authToken } }, // TODO: is bootstrap needed here?
      {
        onSuccess: () => {
          onDone();
        },
        onError: error => {
          logError(`Failed to submit custom data: ${error}`);
          errorToast(error); // TODO: decide what to show on this error toast
        },
      },
    );
  };

  useEffect(() => {
    if (!authToken) return;

    if (mutDecryptUser.isIdle && !mutDecryptUser.isPending) {
      mutDecryptUser.mutate(
        { authToken, fields: customDataDis as CustomDisType[] },
        {
          onError: error =>
            logError(
              `Failed to decrypt user custom data: ${error}. Dis attempted to decrypt: ${customDataDis.join(', ')}`,
            ),
        },
      );
    }
  }, []);

  if (decryptUserMutation.isPending) {
    return (
      <div
        className="flex flex-col gap-6"
        style={{
          height: '100%',
          minHeight: 'var(--loading-container-min-height)',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <CustomDataForm
      pageRequirement={collectCustomDataRequirement.page}
      decryptedData={decryptedCustomData}
      onSubmit={handleSubmitData}
      submissionPending={vaultMutation.isPending}
    />
  );
};

export default CollectCustomData;
