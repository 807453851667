import { cx } from 'class-variance-authority';
import { useId, useMemo } from 'react';
import type { HTMLAttributes } from 'react';
import FormFieldContext from './form-field-context';

export type FormFieldProps = {
  children: React.ReactNode | React.ReactNode[];
  variant?: 'vertical' | 'horizontal';
} & HTMLAttributes<HTMLDivElement>;

const FormField = ({ children, variant = 'vertical', className, ...props }: FormFieldProps) => {
  const id = useId();
  const contextValues = useMemo(() => ({ id }), [id]);

  return (
    <FormFieldContext.Provider value={contextValues}>
      {variant === 'vertical' && (
        <div className={cx('flex flex-col gap-2 w-full overflow-ellipsis whitespace-nowrap', className)} {...props}>
          {children}
        </div>
      )}
      {variant === 'horizontal' && (
        <div className={cx('flex justify-between items-center w-full', className)} {...props}>
          {children}
        </div>
      )}
    </FormFieldContext.Provider>
  );
};

export default FormField;
