import { LoadingSpinner } from '@onefootprint/ui';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useOnboardingValidate } from '../../../../queries';
import Logger from '../../../../utils/logger/logger';
import nid from '../../../../utils/neuro-id';
import ErrorComponent from '../../components/error';
import { useOnboardingMachine } from '../../components/machine-provider';

const Validate = () => {
  const [state, send] = useOnboardingMachine();
  const {
    idvContext: { authToken },
  } = state.context;
  const onboardingValidateMutation = useOnboardingValidate();
  const { isError } = onboardingValidateMutation;

  useEffect(() => {
    if (onboardingValidateMutation.isPending) {
      return;
    }

    nid.complete();
    onboardingValidateMutation.mutate(
      { authToken },
      {
        onSuccess: payload => {
          send({
            type: 'validationComplete',
            payload,
          });
        },
        onError: (error: unknown) => {
          Logger.error(error, { location: 'validate' });
        },
      },
    );
  }, []);

  return isError ? (
    <ErrorComponent />
  ) : (
    <Container>
      <LoadingSpinner />
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing[7]};
    min-height: var(--loading-container-min-height);
    height: 100%;
    justify-content: center;
    text-align: center;
  `}
`;

export default Validate;
