import { HeaderTitle, NavigationHeader } from '@/idv/components';
import type { CustomRequirementPage, CustomRequirementSectionPrompt } from '@onefootprint/request-types';
import { FormProvider, useForm } from 'react-hook-form';
import type { CustomDisType, CustomDisValueType, FormData } from '../../types';
import Form from './components/form';

type CustomDataFormProps = {
  pageRequirement: CustomRequirementPage;
  decryptedData: FormData;
  onSubmit: (data: FormData) => void;
  submissionPending?: boolean;
};

// This function sets the default value for a given property in the form data as nested object
// This is a weird behavior in react hook form, where registering a name with dot notation creates a nested object
// however, using the same name in defaultValues does not create the nested object
// So, we end with with duplicate properties in the form data one as a nested object and one as a flat property
// This function sets the value for the nested object
// example: setDefaultValue(defaultValues, 'custom.property', 'value') will set defaultValues.custom.property = 'value'
function setDefaultValue({
  defaultValues,
  propertyName,
  value,
}: { defaultValues: FormData; propertyName: CustomDisType; value: CustomDisValueType }): void {
  const parts = propertyName.split('.');
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let current: any = defaultValues;

  for (let i = 0; i < parts.length - 1; i++) {
    const part = parts[i];
    if (!(part in current)) {
      current[part] = {};
    }
    current = current[part];
  }

  current[parts[parts.length - 1]] = value;
}

const getValidatedVaultedValue = (prompt: CustomRequirementSectionPrompt, decryptedData: FormData) => {
  if (prompt.di in decryptedData) {
    const vaultedValue = decryptedData[prompt.di as CustomDisType];

    // for checkboxes, we need to check if the value is an array of strings and items are in the options
    if (prompt.config.kind === 'checkboxes') {
      const options = prompt.config.options;
      if (Array.isArray(vaultedValue)) {
        const isValid = vaultedValue.every(value => typeof value === 'string' && options.includes(value));
        if (isValid) {
          return vaultedValue;
        }
      }
    }

    // for multiple choice and dropdown, we need to check if the value is a string and is in the options
    if (prompt.config.kind === 'multiple_choice' || prompt.config.kind === 'dropdown') {
      const options = prompt.config.options;
      if (typeof vaultedValue === 'string' && options.includes(vaultedValue)) {
        return vaultedValue;
      }
    }

    // for checkbox, we need to check if the value is a boolean
    if (prompt.config.kind === 'checkbox') {
      if (typeof vaultedValue === 'boolean') {
        return vaultedValue;
      }
    }

    // for short_text and long_text, we need to check if the value is a string
    if (prompt.config.kind === 'short_text' || prompt.config.kind === 'long_text') {
      if (typeof vaultedValue === 'string') {
        return vaultedValue;
      }
    }
  }
  return undefined;
};

const CustomDataForm = ({ pageRequirement, decryptedData, onSubmit, submissionPending }: CustomDataFormProps) => {
  const { header, subheader, sections } = pageRequirement;
  const defaultValues: FormData = {};
  pageRequirement.sections.forEach(section => {
    if (section.kind !== 'prompt') {
      return;
    }
    const vaultedValue = getValidatedVaultedValue(section, decryptedData);
    if (vaultedValue !== undefined) {
      setDefaultValue({ defaultValues, propertyName: section.di as CustomDisType, value: vaultedValue });
    }
  });

  const formMethods = useForm<FormData>({ defaultValues });

  return (
    <div className="flex flex-col">
      <NavigationHeader leftButton={{ variant: 'close', confirmClose: true }} />
      <div className="flex flex-col gap-8">
        <HeaderTitle title={header} subtitle={subheader} />
        <FormProvider {...formMethods}>
          <Form sections={sections} onSubmit={onSubmit} submissionPending={submissionPending} />
        </FormProvider>
      </div>
    </div>
  );
};

export default CustomDataForm;
