import MarkdownText from '@/idv/components/markdown-text';
import type { CustomRequirementSectionPrompt } from '@onefootprint/request-types';
import { Checkbox, Hint, Radio, Select, TextArea, TextInput } from '@onefootprint/ui';
import { Controller, type FieldError, type FieldErrors, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CustomDisType, FormData } from '../../../../../../types';
import CheckboxesField from './components/checkboxes-field';

type FormFieldProps = {
  prompt: CustomRequirementSectionPrompt;
};

const getNestedError = (path: string, errors: FieldErrors): FieldError | undefined => {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  return path.split('.').reduce((acc: any, key: string) => {
    if (acc && typeof acc === 'object') {
      return acc[key];
    }
    return undefined;
  }, errors);
};

const FormField = ({ prompt }: FormFieldProps) => {
  const { t } = useTranslation('idv', { keyPrefix: 'collect-custom-data.pages.custom-data-form' });
  const { prompt: label, di, required, config } = prompt;
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormData>();
  const displayLabel = required ? label : `${label} (${t('optional')})`;
  const error = getNestedError(di, errors);
  const hasError = !!error;
  const errorMessage = error?.message ? error.message : undefined;

  if (config.kind === 'short_text') {
    return (
      <TextInput
        label={displayLabel}
        placeholder={t('input-placeholder')}
        data-dd-privacy="mask"
        hasError={hasError}
        {...register(di as CustomDisType, { required })}
        hint={hasError ? (errorMessage ?? t('required-input-hints.short-text')) : undefined}
      />
    );
  }

  if (config.kind === 'long_text') {
    return (
      <TextArea
        label={displayLabel}
        placeholder={t('input-placeholder')}
        data-dd-privacy="mask"
        hasError={hasError}
        {...register(di as CustomDisType, { required })}
        hint={hasError ? (errorMessage ?? t('required-input-hints.long-text')) : undefined}
      />
    );
  }

  if (config.kind === 'multiple_choice') {
    const { options } = config;
    return (
      <div className="flex flex-col gap-2">
        <p className="text-label-3">{displayLabel}</p>
        <div className="flex flex-col">
          <div className="flex flex-col gap-3">
            {options.map((option: string) => (
              <Radio
                key={option}
                data-dd-privacy="mask"
                label={option}
                value={option}
                {...register(di as CustomDisType, { required })}
                hasError={hasError}
              />
            ))}
          </div>
          {hasError && <Hint hasError>{errorMessage ?? t('required-input-hints.multiple-choice')}</Hint>}
        </div>
      </div>
    );
  }

  if (config.kind === 'dropdown') {
    const { options } = config;
    const selectOptions = options.map((option: string) => ({ label: option, value: option }));
    return (
      <Controller
        control={control}
        name={di as CustomDisType}
        rules={{
          required,
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Select
              data-dd-privacy="mask"
              label={displayLabel}
              placeholder={t('select-placeholder')}
              options={selectOptions}
              value={selectOptions.find(option => option.value === field.value)}
              onChange={ev => {
                field.onChange(ev.value);
              }}
              onBlur={field.onBlur}
              hasError={!!error}
              hint={error ? (errorMessage ?? t('required-input-hints.dropdown')) : undefined}
            />
          );
        }}
      />
    );
  }

  if (config.kind === 'checkbox') {
    return (
      <div className="flex flex-col">
        <div className="flex">
          <Checkbox
            data-dd-privacy="mask"
            label={<MarkdownText className="text-body-3">{displayLabel}</MarkdownText>}
            {...register(di as CustomDisType, { required })}
            hasError={hasError}
          />
        </div>
        {hasError && <Hint hasError>{errorMessage ?? t('required-input-hints.checkbox')}</Hint>}
      </div>
    );
  }

  if (config.kind === 'checkboxes') {
    const { options } = config;
    return (
      <CheckboxesField
        di={di}
        options={options}
        label={displayLabel}
        required={required}
        hasError={hasError}
        errorMessage={errorMessage}
      />
    );
  }

  return null;
};

export default FormField;
