import { checkIsPhoneValid } from '@/idv/utils';
import { getLogger } from '@/idv/utils/logger';
import { patchHostedIdentifySessionVault } from '@onefootprint/axios';
import { COUNTRIES } from '@onefootprint/core';
import { useRequestError } from '@onefootprint/request';
import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useL10nContext } from '../../l10n-provider';
import type { Context } from '../identify.types';
import getHeader from '../utils/get-header';
import PhonePageStructure from './phone-page-structure';

const { logInfo } = getLogger({ location: 'collect-phone' });

type CollectPhoneProps = {
  context: Context;
  onPrev?: () => void;
  onDone: (phoneNumber: string) => Promise<void>;
};

const CollectPhone = ({ context, onPrev, onDone }: CollectPhoneProps) => {
  const {
    state: { identifyToken, phoneNumber, email },
    onError,
    initArgs: { isLive, logoConfig },
  } = context;
  const { t } = useTranslation('identify');
  const { getErrorContext, getErrorCode } = useRequestError();
  const vaultMutation = useMutation({
    mutationFn: async (phone: string | null) => {
      const { data } = await patchHostedIdentifySessionVault({
        headers: { 'X-Fp-Authorization': identifyToken },
        // @ts-expect-error: Autogenerated types don't support null values
        body: { 'id.phone_number': phone },
        throwOnError: true,
      });
      if (phone) {
        await onDone(phone);
      }
      return data;
    },
    onError: error => {
      const isVaultDataValidationError = getErrorCode(error) === 'T120';
      const context = isVaultDataValidationError ? getErrorContext(error) : {};
      const errorMessage = context['id.phone_number'] ?? error;
      onError('collect-phone', errorMessage);
    },
  });
  const l10n = useL10nContext();
  const options = COUNTRIES;

  const handlePhoneValidation = (phone: string) => {
    const isPhoneValid = checkIsPhoneValid(phone, !isLive);
    if (!isPhoneValid) logInfo('Phone number is not valid');
    return isPhoneValid;
  };

  const handleSubmit = (phoneFromForm: string) => {
    vaultMutation.mutate(phoneFromForm);
  };

  // Clear out the phone number if the user goes back
  const handleGoBack = onPrev
    ? () => {
        vaultMutation.mutate(null, { onSuccess: onPrev });
      }
    : undefined;

  return (
    <PhonePageStructure
      countries={options}
      defaultPhone={phoneNumber?.value}
      email={email?.value}
      Header={getHeader(logoConfig, handleGoBack)}
      isLoading={vaultMutation.isPending}
      l10n={l10n}
      onChangeEmailClick={handleGoBack}
      onSubmit={vaultMutation.isPending ? noop : handleSubmit}
      phoneValidator={handlePhoneValidation}
      texts={{
        headerTitle: t('phone-step.title'),
        headerSubtitle: t('phone-step.subtitle'),
        cta: t('continue'),
        emailChangeCta: t('change'),
        phoneInvalid: t('phone-step.form.input-invalid'),
        phoneLabel: t('phone-number'),
        phoneRequired: t('phone-step.form.input-required'),
      }}
    />
  );
};

export default CollectPhone;
