import type { UserData } from '@/idv/types';
import { isoDateToUserDateLocale } from '@onefootprint/core';
import type { SupportedLocale } from '@onefootprint/footprint-js';

const formatBootstrapData = ({
  bootstrapData,
  locale = 'en-US',
}: {
  bootstrapData: UserData;
  locale?: SupportedLocale;
}): UserData => {
  return Object.fromEntries(
    Object.entries(bootstrapData).map(([key, { value, isBootstrap }]) => {
      if ((key === 'id.dob' || key === 'id.visa_expiration_date') && typeof value === 'string') {
        return [
          key,
          {
            value: isoDateToUserDateLocale(value, locale),
            isBootstrap,
          },
        ];
      }
      return [key, { value, isBootstrap }];
    }),
  );
};

export default formatBootstrapData;
