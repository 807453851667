import type { OnboardingRequirement } from '@onefootprint/types';
import { DocumentRequestKind, OnboardingRequirementKind } from '@onefootprint/types';

/**
 * Given the last top priority requirement and current top priority requirement, returns if the
 * user is stuck on a repeat requirement.
 */
const isRepeatRequirement = (a?: OnboardingRequirement, b?: OnboardingRequirement) => {
  if (!a || !b) {
    return false;
  }
  return requirementKey(a) === requirementKey(b);
};

export const requirementKey = (requirement: OnboardingRequirement) => {
  if (requirement.kind === OnboardingRequirementKind.document) {
    if (requirement.config.kind === DocumentRequestKind.Custom) {
      return `${requirement.kind}-${requirement.config.name}`;
    }
    return `${requirement.kind}-${requirement.config.kind}`;
  }
  if (requirement.kind === OnboardingRequirementKind.collectCustomData) {
    const dis = requirement.page.sections
      .filter(s => s.kind === 'prompt')
      .map(s => s.di)
      .join(',');
    return `${requirement.kind}-${dis}`;
  }
  return requirement.kind;
};

export default isRepeatRequirement;
