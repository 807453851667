import { Checkbox, Hint } from '@onefootprint/ui';
import { useEffect, useRef } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type CheckboxesFieldProps = {
  di: string;
  options: string[];
  label: string;
  required: boolean;
  hasError: boolean;
  errorMessage?: string;
};

const CheckboxesField = ({ di, options, label, required, hasError, errorMessage }: CheckboxesFieldProps) => {
  const { t } = useTranslation('idv', { keyPrefix: 'collect-custom-data.pages.custom-data-form' });
  const parentFormMethods = useFormContext();
  const defaultValues = parentFormMethods.getValues(di) ?? [];
  const currentSelectedValues = useRef<string[]>(defaultValues);
  const formData: Record<string, boolean> = {};
  options.forEach(value => {
    formData[value] = defaultValues.includes(value);
  });
  const { register, watch } = useForm({
    defaultValues: formData,
  });
  const values = watch();

  useEffect(() => {
    const selectedValues = Object.entries(values)
      .filter(([, value]) => value)
      .map(([key]) => key);
    const isSelectionChanged =
      JSON.stringify(selectedValues.sort()) !== JSON.stringify(currentSelectedValues.current.sort());
    if (isSelectionChanged) {
      currentSelectedValues.current = selectedValues;
      parentFormMethods.setValue(di, selectedValues, { shouldValidate: parentFormMethods.formState.isSubmitted });
    }
  }, [values]);

  useEffect(() => {
    parentFormMethods.register(di, { required });
    return () => {
      parentFormMethods.unregister(di);
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <p className="text-label-3">{label}</p>
      <div className="flex flex-col">
        <div className="flex flex-col gap-3">
          {options.map((option: string) => (
            <Checkbox key={option} data-dd-privacy="mask" label={option} {...register(option)} hasError={hasError} />
          ))}
        </div>
        {hasError && <Hint hasError>{errorMessage ?? t('required-input-hints.checkboxes')}</Hint>}
      </div>
    </div>
  );
};

export default CheckboxesField;
